import accounting from 'accounting';
import {IDataSet} from './App';

export const chartConfig = {
  responsive: true,
  tooltips: {
    mode: 'index',
    filter(item: ILabel, data: IChartData) {
      return item.yLabel > 0;
    },
    itemSort(a: ILabel, b: ILabel, data: IChartData) {
      if (a.yLabel > b.yLabel) {
        return 1;
      } else if (a.yLabel === b.yLabel) {
        return 0;
      } else if (a.yLabel < b.yLabel) {
        return -1;
      }
      return 0;
    },
    callbacks: {
      title(items: ILabel[]) {
        const [item, ...rest] = items;
        const {xLabel} = item;
        // xLabel is a date string M/D/YYYY
        // only want to display Month / Year
        const [m, d, y] = xLabel.split('/');
        return `${m}/${y}`;
      },
      label(item: ILabel, data: IChartData) {
        const label = data.datasets[item.datasetIndex].label;
        return `${label}: ${accounting.formatMoney(item.value, '$', 0)}`;
      }
    }
  },
  scales: {
    xAxes: [{
      stacked: true,
      type: 'time',
      time: {
        unit: 'month'
      },
      scaleLabel: {
        display: true,
        labelString: 'Period (Month/Year)'
      }
    }],
    yAxes: [{
      stacked: true,
      id: 'axis1',
      ticks: {
        beginAtZero: true,
        callback(value: number, index: number, values: any[]) {
          return accounting.formatMoney(value, '$', 0);
        }
      },
      offset: true,
      scaleLabel: {
        display: true,
        labelString: 'Expenditures ($)'
      }
    }, {
      stacked: true,
      id: 'axis2',
      position: 'right',
      ticks: {
        beginAtZero: true,
        callback(value: number, index: number, values: any[]) {
          return accounting.formatMoney(value, '$', 0);
        }
      },
      scaleLabel: {
        display: true,
        labelString: 'Cumulative Expenditures ($)'
      }
    }]
  }
}

interface IChartData {
  datasets: IDataSet[];
  labels: string[];
}

interface ILabel {
  datasetIndex: number;
  index: number;
  label: string;
  value: string;
  x: number;
  xLabel: string;
  y: number;
  yLabel: number;
}