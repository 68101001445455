import {ajax, ICDataResponse, IODataParams} from './ajax';
import {AxiosPromise} from 'axios';
import {Omit} from 'yargs';


export const getBurnRateData = (projectNo: string): AxiosPromise<ICDataResponse<BurnData>> => {
  const params = {
    '$orderby': 'periodTo',
    '$filter': `projectNo eq '${projectNo}' and amount ne 0`
  };
    return ajax.get('cmDashProjectBurnRate',{params})
};

export type BurnData = {
  fundSource: string;
  amount: string;
  projectNo: string;
  periodTo: string;
};

export const getFacilitiesData = (): AxiosPromise<ICDataResponse<IAllBurnRate>> => {
  const params: IODataParams = {
    '$orderby': 'periodTo',
    '$filter': `amount ne 0`
  };
  return ajax.get('cmDashAllBurnRate', {params})
};

export interface IAllBurnRate extends Omit<BurnData, 'projectNo'> {}

export const getProjectInfo = (pid: number): AxiosPromise<ICDataResponse<IProjectInfo>> => {
  const params: IODataParams = {
    $filter: `pid eq ${pid}`,
    $select: 'campus,deptid,projectNo,projectTitle'
  };
  return ajax.get('unifierProjectInfo', {params})
};

export interface IProjectInfo {
  pid: number;
  deptid: string;
  projectTitle: string;
  projectNo: string;
  campus: string;
}

