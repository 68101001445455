import Axios from 'axios'

export const ajax = Axios.create({
  baseURL: 'https://pcm.sandi.net/apiserver/api.rsc/',
  headers:{'x-cdata-authtoken': '9b2P7g8r0Q1a1j8Y2g1u'}
});

export interface ICDataResponse<T> {
  value: T[];
}

export interface IODataParams {
  $select?: string;
  $filter?: string;
  $top?: number;
  $limit?: number;
  $skip?: number;
  $count?: string;
  $orderby?: string;
}