import React from 'react';
import Spin from 'react-spinkit';

const Spinner: React.FC<ISpinnerProps> = () => {
  return (
    <span style={{display: 'flex', justifyContent: 'center', marginTop: '25vh'}}>
      <Spin name="ball-clip-rotate-multiple" />
    </span>
  )
};

export default Spinner;

interface ISpinnerProps {}