import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
import {getBurnRateData, getFacilitiesData, getProjectInfo, BurnData, IAllBurnRate} from './api';
import Spinner from './Spinner';
import {ICDataResponse} from './ajax';
import {AxiosPromise} from 'axios';
import {chartConfig} from './chartConfig';
const FACILITIES = 1001;

const tWin: any = window;
const {postMessage, constants: {GET_TAB_INFO}} = tWin.unifierExtensions;

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      errorMessage: '',
      end: new Date(),
      start: new Date(),
      burnRateData: [],
      labels: [],
      isLoading: true
    };
  }

  componentWillMount() {
    /*
    * Attach listener for unifier extension messaging system.
    * */
    if(process.env.NODE_ENV !== 'production') {
      return this.getData(FACILITIES as unknown as string);
    } else {
      // production, add event listener for unifier IFC system
      window.addEventListener('message', this.onUnifierMessage);
      // ask unifier which project it is in
      this.getTabInfo();
    }
  }

  getTabInfo = () => {
    postMessage({type: GET_TAB_INFO, project_id: '1'});
  };

  onUnifierMessage = (e: any) => {
    // early exit if viewing outside of unifier
    if (e.origin === 'https://cmdashboard.cisweb.net') {
      return;
    }
    if (e?.data?.pid) {
      const {pid} = e.data;
      console.log(`Received PID ${pid}`);
      if(pid === FACILITIES) {
        this.getData(String(FACILITIES));
      } else {
        getProjectInfo(pid)
          .then((res) => {
            const {projectNo} = res.data.value[0];
            this.getData(projectNo);
          });
      }
    }
  };

  getData =  async (projectNo: string) => {
    let getData: (projectNo: string) => AxiosPromise<ICDataResponse<IAllBurnRate>>;
    if (projectNo == String(FACILITIES)) {
      getData = getFacilitiesData;
    } else {
      getData = getBurnRateData;
    }
    try {
      const res = await getData(projectNo);
      if (!res?.data?.value?.length) {
        return this.setState({
          errorMessage: 'No data!',
          isLoading: false
        });
      }
      const burnRateData = res.data.value;
      const start = new Date(burnRateData[0].periodTo);
      const end = new Date(burnRateData[burnRateData.length - 1].periodTo);
      this.setState({
        burnRateData,
        start,
        end,
        labels: this.getLabels(start, end),
        isLoading: false
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errorMessage: `Error: ${err.message}`,
        isLoading: false
      })
    }
  };

  createDataSets = (): IDataSet[] => {
    // this method creates the chart data sources
    // separates fundSources into own object with map of dates and values
    // object shape: dataSets = {dataSetName:{date: value}}
    // loop through each label (MM/YYYY), and create an array of objects with x (label) y (value=0)
    // return IDataSet[]
    const {burnRateData, labels} = this.state;

    // map of fund and date-values
    // shape: {dataSetName:{date: value}}
    const fundDateMap: any = {};
    const dataSets: IDataSet[] = [];

    // cumulative data
    // shape: {date: value};
    const cumulativeDateMap: any = {};

    // Loop through data, separate out into datasets, create cumulative dataset
    for (let i = 0; i < burnRateData.length; i++) {
      const {fundSource = 'Current Payment Due per Month', amount, periodTo} = burnRateData[i];
      const period = this.getLabel(new Date(periodTo));
      if (!fundDateMap[fundSource]) {
        fundDateMap[fundSource] = {};
      }
      // check if mm/yyyy already exists, if so sum values
      if (fundDateMap[fundSource][period]) {
        fundDateMap[fundSource][period] += amount;
      } else {
        fundDateMap[fundSource][period] = amount;
      }
      if (cumulativeDateMap[period]) {
        cumulativeDateMap[period] += amount;
      } else {
        cumulativeDateMap[period] = amount;
      }
    }
    // get fundSources
    const funds = Object.entries(fundDateMap);
    const cumulativeData: IXY[] = [];
    // create datasets with exact labels
    const dataSetMap: any = {};
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];

      for (let j = 0; j < funds.length; j++) {
        const [fund, values] = funds[j];
        dataSetMap[fund] = dataSetMap[fund] || [];
        // @ts-ignore
        dataSetMap[fund].push({x: label, y: values[label] || 0});
      }

      // create cumulative xy data with
      cumulativeData.push({x: label, y: cumulativeDateMap[label] || 0});
    }

    // create datasets for cumulative amounts on yAxis 2
    /*for (let i = 0; i < funds.length; i++) {
      const [fund] = funds[i];
      const data = dataSetMap[fund].map(({x, y}: IXY, index: number, arr: IXY[]) => ({
        x,
        y: arr.reduce((previousValue, currentValue, currentIndex) => {
          // early exit for data ocurring after this
          if (currentIndex > index) return previousValue;
          return previousValue + currentValue.y;
        }, 0)
      }));

      dataSets.push(this.createDataSet(`${fund} (Cumulative)`, data, {
        type: 'line',
        yAxisID: 'axis2',
        backgroundColor: 'rgba(0,0,0,0)'
      }));
    }*/

    // create datasets
    for (let i = 0; i < funds.length; i++) {
      const [fund] = funds[i];
      dataSets.push(this.createDataSet(fund, dataSetMap[fund]));
    }
    const cumulativeDataSet = cumulativeData.map(({x, y}: IXY, index: number, arr: IXY[]) => ({
      x,
      y: arr.reduce((previousValue, currentValue, currentIndex) => {
        // early exit for data ocurring after this
        if (currentIndex > index) return previousValue;
        return previousValue + currentValue.y;
      }, 0)
    }));

    dataSets.push(this.createDataSet('Total Work Completed', cumulativeDataSet ,{type: 'line', yAxisID: 'axis2', backgroundColor: 'rgba(0,0,0,0)'}));

    return dataSets;
  };

  createDataSet = (label: string, data: IXY[], other: any = {yAxisID: 'axis1'}) => {
    const color = getRandomColor();
    return {
      label,
      data,
      backgroundColor: color + '50',
      borderColor: color,
      borderWidth: 1,
      ...other
    };
  };

  getLabel = (date: Date): string => `${date.getMonth() + 1}/1/${date.getFullYear()}`;

  getLabels = (start: Date, end: Date): string[] => {
    // create date periods based on start, end dates
    // return array of date strings in MM/DD/YYYY format, with date set to 1st of the month
    const dateLabels: string[] = [];
    const num = this.getMonths(start, end);
    start.setDate(1); // set start date to 1st to save us from months not having same length
    dateLabels.push(this.getLabel(start));
    for (let i = 1; i <= num + 1; i++) {
      start.setMonth(start.getMonth() + 1);
      dateLabels.push(this.getLabel(start));
    }
    return dateLabels;
  };

  getMonths = (from: Date, to: Date): number => {
    let months;
    months = (to.getFullYear() - from.getFullYear()) * 12;
    months -= from.getMonth() + 1;
    months += to.getMonth();
    return months <= 0 ? 0 : months;
  };

  render() {
    const {
      isLoading, errorMessage
    } = this.state;
    return (
      <div>
        <h1>CM Department: Unifier Construction Burn Rate</h1>
        {isLoading ? <Spinner/> : (
          errorMessage ? <h4>{errorMessage}</h4> : (
            <Bar
              data={{datasets: this.createDataSets()}}
              height={100}
              options={chartConfig}
            />
          )
        )
        }
      </div>
    );
  }
}


export default App;

interface IAppState {
  errorMessage: string;
  burnRateData: BurnData[] | IAllBurnRate[];
  start: Date;
  end: Date;
  labels: string[];
  isLoading: boolean;
}

interface IAppProps {
}

export interface IDataSet {
  label: string;
  data: IXY[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

interface IXY {
  y: number;
  x: string;
}

function getRandomColor() {
  var letters = '0123456789ABCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}